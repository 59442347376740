export enum Status {
  Pending = 'pending',
  Completed = 'completed',
  Failed = 'failed',
}

export interface PaymentBase {
  ID: string;
  InsuranceID: string;
  CustomerID: string;
  PersonID?: string; // fyi/ support jwt with 'person' role (microservices issue)
  MarketCountry: string;
  Status: Status;
  CreatedAt: string;
  UpdatedAt: string;
  Amount: number;
}

// TODO split up for 3 mandate types, sync with openapi specs
export interface Payment extends PaymentBase {
  CompleteURL?: string;
  PaymentURL?: string;
  UserAgent: string;
  OrderID: string;
  EmbedURL: string;
  CardNumberTruncated: string;
  CardExpiryMonth: number;
  CardExpiryYear: number;
  RegistrationNumber?: string;
  AccountNumber?: string;
}

export interface BankIdPaymentMandate extends PaymentBase {
  CompletedAt?: string;
  AssentlyJWT: string;
  Agreement: string;
  AgreementDigest: string;
  AccountNumber: string;
  ClearingNumber: string;
  PaymentSetupAt?: string;
  PaymentSetupIgnored?: boolean;
  PaymentSetupFollowupAt?: string;
  AnonymizedAt?: string;
}

export interface CardPaymentMandate extends PaymentBase {
  EmbedURL: string;
}

export type CreateCardPaymentMandateRequest = Pick<
  Payment,
  | 'InsuranceID'
  | 'CustomerID'
  | 'PersonID'
  | 'MarketCountry'
  | 'PaymentURL'
  | 'CompleteURL'
  | 'UserAgent'
  | 'Amount'
>;

export type CreateKidPaymentMandateRequest = Pick<
  Payment,
  | 'InsuranceID'
  | 'CustomerID'
  | 'PersonID'
  | 'MarketCountry'
  | 'CompleteURL'
  | 'Amount'
>;

export type CreateBsPaymentMandateRequest = Pick<
  Payment,
  | 'InsuranceID'
  | 'CustomerID'
  | 'PersonID'
  | 'MarketCountry'
  | 'Amount'
  | 'RegistrationNumber'
  | 'AccountNumber'
>;

export type CreateBankIdPaymentMandateRequest = Pick<
  BankIdPaymentMandate,
  | 'InsuranceID'
  | 'CustomerID'
  | 'PersonID'
  | 'MarketCountry'
  | 'AccountNumber'
  | 'ClearingNumber'
> & {
  SourceURL: string;
};

export interface FindPaymentInput {
  insuranceID?: string;
  marketCountry?: string;
  customerID?: string;
}

export interface FindPaymentResponse {
  Items: Payment[];
}

export type CreateBankIdPaymentMandateFinalizeRequest = {
  MandateID: string;
  PolicyID: string;
  Amount: number;
  AssentlyToken: string;
};
