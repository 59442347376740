import clsx from 'clsx';
import styles from './icon.module.scss';
import { IconComponents, IconName } from './Icons.inventory';

export const IconSizes = ['xs', 's', 'm', 'l'] as const;

type IconProps = {
  name: IconName;
  size?: (typeof IconSizes)[number];
  className?: string;
};

/**
 * FIXME this approach is not ideal, as
 * - it requires importing all svgs,
 *   + which is not ideal for performance when we have a lot of icons
 *   + the icons are not tree-shaken, so we end up with a lot of unused code
 * - the syntax `<Icon name="info" />` is not very intuitive → `<InfoIcon />` would be better
 *
 * A better approach would be to use a build script to generate React components from the svgs.
 */

export const Icon = ({ name = 'info', size = 'm', className }: IconProps) => {
  const IconComponent = IconComponents[name];

  const isSolid =
    name.includes('solid') ||
    // new icon set `fill-16` | `fill-24` | `fill-32`
    name.includes('fill-') ||
    // new icon set `outline-16` | `outline-24` | `outline-32`
    name.includes('outline-');

  return (
    <IconComponent
      className={clsx(styles.icon, className)}
      data-size={size}
      data-solid={isSolid}
      aria-hidden
    />
  );
};
